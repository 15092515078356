<template>
  <div class="wrapper wrapper_home" id="home">
    <div class="links-main">
      <div class="container-link" data-cy="list-links"> 
          <div><a href="https://twitter.com/BringFinance" target="_blank" rel="nofollow"><span class="twitter"></span></a></div>
          <div><a href="https://medium.com/@BringFinance" target="_blank" rel="nofollow"><span class="medium"></span></a></div>
          <div><a href="https://www.youtube.com/channel/UCyG5ZG13kiXvvSeRxdIG_jg"  target="_blank" rel="nofollow"><span class="youtube"></span></a></div>
          <div><a href="https://www.reddit.com/r/bRingFinance/"  target="_blank"><span class="reddit" rel="nofollow"></span></a></div>
          <div><a href="https://t.me/bRing_community"  target="_blank"><span class="telegram" rel="nofollow"></span></a></div>
          <div><a href="https://t.me/bRing_channel" target="_blank"><span class="telegram speaker" rel="nofollow"> <i class="i-speaker"></i></span></a></div>
        </div>
    </div>
  <header-component />
    <div>
      <main class="landing" >
        <section class="main_section">
          <div class="container">
             <div class="" id="our_partners">
              <h1>{{lang.get("MAIN_TITLE")}}</h1>
              <!-- <h3></h3> -->
              <!-- <button class="button button_launch-app" data-cy="button_launch-app-main">Launch app</button> -->
            </div> 
            <div class="container-partners" >
              <h3>{{lang.get("PARTNERS")}}</h3>
              <div class="partners" data-cy="list-partners">
                <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xf7413489c474ca4399eee604716c72879eea3615&use=V2" rel="nofollow" class="apyswap" target="_blank"></a>
                <a href="https://yellowroad.app/" rel="nofollow" class="yeallowroad" target="_blank"></a>
                <!-- <a href="https://enecuum.com/" class="enq" target="_blank"></a> -->
                <a href="https://a2dao.com/" rel="nofollow" class="a2dao" target="_blank"></a>
                <a href="https://kyros.ventures/" rel="nofollow" class="kyros" target="_blank"></a>
                <a href="https://app.uniswap.org/#/swap?outputCurrency=0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54&use=V2" rel="nofollow" class="hapi" target="_blank"></a>
                <a href="https://1mlnnfts.com/" rel="nofollow" class="mil_ntf" target="_blank"></a>
                <a href="https://peanut.trade/" rel="nofollow" class="peanut" target="_blank"></a>
                <a href="https://www.raze.network/" rel="nofollow" class="raze" target="_blank"></a>
                <a href="https://ferrum.network/" rel="nofollow" class="ferrum" target="_blank"></a>
                <a href="https://memeunity.com/" rel="nofollow" class="memeunity" target="_blank"></a>
                <a href="https://dehive.finance/" rel="nofollow" class="dehive" target="_blank"></a>
                <a href="https://coinmarketcap.com/ru/currencies/bring-finance/" rel="nofollow" class="coinmarket" target="_blank"></a>
                <a href="https://bulknetwork.xyz/" rel="nofollow" class="bulknetwork" target="_blank"></a>
                <a href="https://www.hedget.com/" rel="nofollow" class="hadge" target="_blank"></a>
                <a href="https://berrydata.co/#/home" rel="nofollow" class="berry" target="_blank"></a>
              </div>
            </div>
          </div>
        </section>
        <section class="our_service" id="bRing_features">
          <div class="container">
            <div class="two_parts">
              <div>
                <h2>{{lang.get("MAIN_FEATURES_TITLE")}}</h2>
                <h3>{{lang.get("MAIN_FEATURES_SUBTITLE")}}</h3>
                  <ul class="advantages">
                    <li>
                      <div><i class="i-coins"></i></div> <span>{{lang.get("FEATURE_1")}}</span>
                    </li>
                    <li>
                      <div><i class="i-grow"></i></div> <span>{{lang.get("FEATURE_2")}}</span>
                    </li>
                    <li>
                      <div><i class="i-wallet"></i></div> <span>{{lang.get("FEATURE_3")}}</span>
                    </li>
                    <li>
                      <div><i class="i-unlock"></i></div> <span>{{lang.get("FEATURE_4")}}</span>
                    </li>
                  </ul>
              </div>
              <div class="block-image">
                <div class="image">
                  <div class="image-wrapper">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <ul class="our_statistics">
              <li>
                <h2>--</h2>
                <h4>{{lang.get("STATS_1")}}</h4>
              </li>
              <li>
                <h2>--</h2>
                <h4>{{lang.get("STATS_2")}}</h4>
              </li>
              <li>
                <h2>--</h2>
                <h4>{{lang.get("STATS_3")}}</h4>
              </li>
              <li>
                <h2>--</h2>
                <h4>{{lang.get("STATS_4")}}</h4>
              </li>
            </ul> -->
          </div>
        </section>
        <section class="how_it_works" id="bRing_pools">
          <div class="container">
            <h2>bRing {{lang.get("POOLS")}}</h2>
            <!-- <h3> </h3> -->
              <ul class="steps_works">
                <li>
                  <div class="step"><span class="count"> 1 </span></div>
                  <h3></h3>
                  <h4>{{lang.get("STEP_GUIDE_1")}}</h4>
                </li>
                <li>
                  <div class="step"><span class="count"> 2 </span></div>
                  <h3></h3>
                  <h4>{{lang.get("STEP_GUIDE_2")}}</h4>
                </li>
                <li>
                  <div class="step"><span class="count"> 3 </span></div>
                  <h3></h3>
                  <h4>{{lang.get("STEP_GUIDE_3")}}</h4>
                </li>
                <li>
                  <div class="step"><span class="count"> 4 </span></div>
                  <h3></h3>
                  <h4>{{lang.get("STEP_GUIDE_4")}}</h4>
                </li>
              </ul>
             <button v-if="currentAddress"  @click="$router.push({name: 'pool'})" class="button button_launch-app" data-cv="btn-launch-app">{{lang.get("LAUNCH_APP")}}</button>
          </div>
        </section>
        <section class="roadmap" id="roadmap">
          <div class="container">
            <h2>{{lang.get("ROADMAP")}}</h2>
            <!-- <h3></h3> -->
          </div>
          <ul class="roadmap-list">
            <li>
              <div>                
                <div class="title">Q1 2021</div>
                <div class="block"></div>
              </div>
            </li>
            <li class="active">
              <div>                
                <div class="title">Q2 2021</div>
                <div class="block">
                  <div></div>
                </div>
                  <div class="roadmap_description">
                    {{lang.get("ROADMAP_DESC_1")}}
                  </div>
              </div>
            </li>
            <li class="active">
              <div>                
                <div class="title">Q3 2021</div>
                <div class="block">
                  <div></div>
                </div>
                <div>
                  <div class="roadmap_description">
                    {{lang.get("ROADMAP_DESC_2")}}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div>                
                <div class="title">Q4 2021</div>
                <div class="block"></div>
              </div>
            </li>
          </ul>
        </section>
        <section class="questions" id="questions">
          <h2>{{lang.get("FAQs")}}</h2>
          <faq-blocks :faqs="faqs"  @openFaq="openFaq($event)"/>
        </section>
      </main>
    </div>
  </div> 
</template>

<script>
  import MultiLang from '@/core/multilang'; 
  import FaqBlocks from '../components/FaqBlocks.vue';
  import {mapState} from 'vuex';
import HeaderComponent from '../components/HeaderComponent.vue';

  export default {
    components: {
      FaqBlocks,
      HeaderComponent  
    },
   
      computed:{
      ...mapState(['currentAddress']),
      addressShort() {
        if(this.currentAddress) {
          return this.currentAddress.slice(0, 3) + "..." + this.currentAddress.slice(this.currentAddress.length - 3, this.currentAddress.length)
        }
      }
    },
    data: function() {
      return{
        lang: new MultiLang(this),
        faqs: [
        {
          title: "FAQ_1_TITLE",
          description:
            "FAQ_1_TEXT",
          isOpen: false,
        },
        {
          title: "FAQ_2_TITLE",
          description:
            "FAQ_2_TEXT",
          isOpen: false,
        },
        /*{
          title: "FAQ_3_TITLE",
          description:
            "FAQ_3_TEXT",
          isOpen: false,
        },*/
        {
          title: "FAQ_4_TITLE",
          description:
            "FAQ_4_TEXT",
          isOpen: false,
        },
        {
          title: "FAQ_5_TITLE",
          description:
            "FAQ_5_TEXT",
          isOpen: false,
        },
        {
          title: "FAQ_6_TITLE",
          description:
            "FAQ_6_TEXT",
          isOpen: false,
        },
        {
          title: "FAQ_7_TITLE",
          description:
            "FAQ_7_TEXT",
          isOpen: false,
        },
        {
          title: "FAQ_8_TITLE",
          description:
            "FAQ_8_TEXT",
          isOpen: false,
        },
        {
          title: "FAQ_9_TITLE",
          description:
            "FAQ_9_TEXT",
          isOpen: false,
        },
        {
          title: "FAQ_10_TITLE",
          description:
            "FAQ_10_TEXT",
          isOpen: false,
        },

      ]
      }
    },
    mounted() {
    this.lang.init()
  },
  methods: {
    //TODO change when deployed to binance
    connectWallet() {
      try {
        let currentAccount = localStorage.getItem("address")
        if(window.ethereum){
                window.ethereum
                  .request({ method: 'eth_requestAccounts' })
                  .then(handleAccountsChanged)
                  .catch((err) => {
                    if(err.code == "-32002"){
                      alert('Proceed to your metamask extension')
                    };
                  });

                // Note that this event is emitted on page load.
                // If the array of accounts is non-empty, you're already
                // connected.
                window.ethereum.on('accountsChanged', handleAccountsChanged);

              // For now, 'eth_accounts' will continue to always return an array
              function handleAccountsChanged(accounts) {
                if (accounts.length === 0) {

                  localStorage.removeItem('address')
                  _this.$store.commit("setCurrentAddress", "0x0000000000000000000000000000000000000000")

                  // MetaMask is locked or the user has not connected any accounts
                  // alert('Please connect to MetaMask.');
                } else if (accounts[0] !== currentAccount) {
                  currentAccount = accounts[0];
                  localStorage.setItem("address", currentAccount);
                        // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
                  location.reload();
                }
              }
        }
      } catch (error) {
        console.log(error);
      }
    },
    openFaq(index) {
      this.faqs.map((val, key) => {
        if (val.isOpen && key === index) val.isOpen = false;
        else val.isOpen = key === index;
        return val;
      });
    },
  }
}
</script>