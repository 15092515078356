import {ethers} from 'ethers';
import Config from '../../Config.json'

import {abi} from "../abi/contractAbi";
import {V2} from "../abi/V2";


export default class Core {
    constructor(vueContext){
        this.context = vueContext
        this.farmAddress = Config.FARM_ADDRESS
        this.farmAddress_2 = Config.FARM_ADDRESS_2
        this.farmAdminAddress = Config.FARM_ADMIN_ADDRESS
        this.defaultReferrer = Config.DEFAULT_REFERRER
        this.init()
    }
    async checkAccount(accountType){
        if(window.ethereum && accountType === 'metamask'){

            const connected = window.ethereum
              .request({ method: 'eth_requestAccounts' })
              .then(handleAccountsChanged).then(res => res)
              .catch((err) => {
                console.error(err);
              });

            // Note that this event is emitted on page load.
            // If the array of accounts is non-empty, you're already
            // connected.
            window.ethereum.on('accountsChanged', handleAccountsChanged);

          // For now, 'eth_accounts' will continue to always return an array
          function handleAccountsChanged(accounts) {
            if (accounts.length === 0) {
                return false
              // MetaMask is locked or the user has not connected any accounts
              // alert('Please connect to MetaMask.');
            }else if (accounts.length > 0) {
                return true
            }
          }
          return connected

    }else if(window.BinanceChain && accountType === 'binance') {
             
            const connected = window.BinanceChain
              .request({ method: 'eth_accounts' })
              .then(handleAccountsChanged).then(res=>{
                  console.log(res);
                  return res
              })
              .catch((err) => {
                // Some unexpected error.
                // For backwards compatibility reasons, if no accounts are available,
                // eth_accounts will return an empty array.
                console.error(err);
              });

            // Note that this event is emitted on page load.
            // If the array of accounts is non-empty, you're already
            // connected.
            window.BinanceChain.on('accountsChanged', handleAccountsChanged);

            // For now, 'eth_accounts' will continue to always return an array
            function handleAccountsChanged(accounts) {
              try {
                if (accounts.length === 0) {
                return false
                // Binance Chain Wallet is locked or the user has not connected any accounts
              } else if (accounts.length > 0) {
                return  true
              }
              } catch (error) {
                console.log(error);
              }
            }
            return connected
    
    // location.reload()
        }
    }

    async init() {
        if(!this.contract){
            if(window.localStorage.getItem("selectedWallet") === "metamask" && window.ethereum){
                
                if(window.ethereum.chainId === "0x38" || window.ethereum.chainId === 56) {


                    let provider =  new ethers.providers.Web3Provider(window.ethereum)
                    let providerV2 = new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.defibit.io/");
                    // const connected = await ethereum._metamask.isUnlocked()
                    const connected = await this.checkAccount('metamask')
                     
                    if(connected){
                         
                        this.providerAddress = provider;
                        this.signer = provider.getSigner() ;
                        this.V1 = new ethers.Contract(this.farmAddress,  abi, provider).connect(this.signer);
                        this.V2 = new ethers.Contract(this.farmAddress_2,  V2, providerV2).connect(this.signer);
                    
                        return this.V1;
                    }
                     
                    this.providerAddress = provider;
                    this.signer = provider.getSigner() ;
                    this.V1 = new ethers.Contract(this.farmAddress,  abi, provider).connect(provider);
                    this.V2 = new ethers.Contract(this.farmAddress_2,  V2, providerV2).connect(providerV2);
                
                    return this.V1;
                    
                }else {
                    throw new Error("Please change the network in Metamask to mainnet")
                }
                
            }else if (window.localStorage.getItem("selectedWallet") === "binance" && window.BinanceChain) {
                if(window.BinanceChain.chainId === "0x38" || window.BinanceChain.chainId === 56) {

                    const connected = await this.checkAccount('binance')
                     
                    if(connected){
                          
                        let provider =  new ethers.providers.Web3Provider(window.BinanceChain);
                        let providerV2 = new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.defibit.io/");
                        this.providerAddress = provider;
                        this.signer = provider.getSigner()
                        this.V1 = new ethers.Contract(this.farmAddress,  abi, provider).connect(this.signer);
                        this.V2 = new ethers.Contract(this.farmAddress_2,  V2, providerV2).connect(this.signer);

                        return this.V1;
                    }
                     
                    let provider =  new ethers.providers.Web3Provider(window.BinanceChain);
                    let providerV2 = new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.defibit.io/");
                    this.providerAddress = provider;
                    this.signer = provider.getSigner()
                    this.V1 = new ethers.Contract(this.farmAddress,  abi, provider).connect(provider);
                    this.V2 = new ethers.Contract(this.farmAddress_2,  V2, providerV2).connect(providerV2);

                    return this.V1;
                    
                }else {
                    throw new Error("Please change the network in Binance Chain Wallet to mainnet")
                }
                
            }else {
                let provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.defibit.io/");
                let providerV2 = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org/");
                this.providerAddress = provider;
                this.signer = provider.getSigner();
                this.V1 = new ethers.Contract(this.farmAddress,  abi, provider).connect(provider);
                this.V2 = new ethers.Contract(this.farmAddress_2,  V2, providerV2).connect(providerV2);

                return this.V1;
            }
            
        }

        return this.V1;
    }

    

    getSiteData(period=360000){
        
        let _this = this;

        setTimeout(async function tick() {

            try {
                // let totalTokensNumber = await _this.totalTokensCount()
                // let farmTokens = await _this.getFarmingTokens()
                let stakeTokens = Config.stakeTokens;
                let tokens = Config.tokens

                let tokensTotalStake = {
                    V1: {},
                    V2: {}
                }

                for(let i = 0; i < tokens.length; i++){
                   let tokenAddress = tokens[i].address
                   let {V1TotalStake, V2TotalStake} = await _this.getTotalStaking(tokens[i].address, tokens[i].decimals)

                   tokensTotalStake.V1[tokenAddress.toLowerCase()] = V1TotalStake
                   tokensTotalStake.V2[tokenAddress.toLowerCase()] = V2TotalStake
                } 

                // _this.context.$store.commit('setTotalTokensNumber', totalTokensNumber);
                _this.context.$store.commit('setStakeTokens', stakeTokens);
                _this.context.$store.commit('setTokensTotalStake', tokensTotalStake);



            setTimeout(tick, period);
            } catch (ex) {
            console.log(ex);

            setTimeout(tick, 300);
            }
        }, 300);

        
    }

    updateUserInfo(userAddress, period=10000){
        let _this = this;

        setTimeout(async function tick() {
            try {
                let stakeTokens = _this.context.$store.getters.getStakeTokens
                let tokensTotalStake = _this.context.$store.getters.getTokensTotalStake;
                

                //TODO add alternative check
                // if(!farmTokens.length){
                //     throw new Error
                // }

                    //getting raw stake data from all contracts 
                  let V1StakingDataRaw =  await _this.getStakingDetails(userAddress, "V1");
                  let V2StakingDataRaw =  await _this.getStakingDetails(userAddress, "V2");
                
                  ///getting  stake rewards from all contracts

                  for (let i=0; i < V1StakingDataRaw.length; i++){
                    if(!V1StakingDataRaw[i].isActive){

                      let stakeAmount = await _this.getStakeAmount(userAddress, V1StakingDataRaw[i].stakeId, "V1", V1StakingDataRaw[i].decimals);
                      V1StakingDataRaw[i].stakeAmount = stakeAmount


                    }
                  }

                  for (let i=0; i < V2StakingDataRaw.length; i++){
                    if(!V2StakingDataRaw[i].isActive){

                      let stakeAmount = await _this.getStakeAmount(userAddress, V2StakingDataRaw[i].stakeId, "V2", V2StakingDataRaw[i].decimals);
                      V2StakingDataRaw[i].stakeAmount = stakeAmount


                    }
                  }

                  //getting stake data for each pool contract

                  let V1StakingData = V1StakingDataRaw.map(el => {
  
                    let stakeTokenAddress = el.tokenAddress;
                    let farmTokensList = stakeTokens.find(stakeToken => stakeToken.address.toLowerCase() === stakeTokenAddress.toLowerCase() && stakeToken.poolVersion === "V1").farmTokensList;
                    
                    let stakeTokenData = Config.stakeTokens.find(stakeToken => stakeTokenAddress.toLowerCase() === stakeToken.address.toLowerCase()  && stakeToken.poolVersion === "V1");
                    
                    return {
                      ...el,
                      name: stakeTokenData.name,
                      maxStake: stakeTokenData.totalMaxStake,
                      coefTokens: stakeTokenData.coefTokens,
                      userMaxStake: stakeTokenData.userMaxStake,
                      decimals: stakeTokenData.decimals,
                      farmTokensList,
                      poolVersion: "V1"
                    }
                  })
                  

                  let V2StakingData = V2StakingDataRaw.map(el => {
  
                    let stakeTokenAddress = el.tokenAddress;
                    let farmTokensList = stakeTokens.find(stakeToken => stakeToken.address.toLowerCase() === stakeTokenAddress.toLowerCase() && stakeToken.poolVersion === "V2").farmTokensList;
                    
                    let stakeTokenData = Config.stakeTokens.find(stakeToken => stakeTokenAddress.toLowerCase() === stakeToken.address.toLowerCase() && stakeToken.poolVersion === "V2");
                    
                    return {
                      ...el,
                      name: stakeTokenData.name,
                      maxStake: stakeTokenData.totalMaxStake,
                      coefTokens: stakeTokenData.coefTokens,
                      userMaxStake: stakeTokenData.userMaxStake,
                      decimals: stakeTokenData.decimals,
                      farmTokensList,
                      poolVersion: "V2"
                    }
                  })
                  
                  let V1StakingDataWithReward =  await _this.calcRewardForStakeTokens(V1StakingData, tokensTotalStake.V1, "V1")
                  let V2StakingDataWithReward = await _this.calcRewardForStakeTokens(V2StakingData, tokensTotalStake.V2, "V2")
                  

                  let stakingData = [...V1StakingDataWithReward, ...V2StakingDataWithReward]

                  const activeStakes = stakingData.filter(el => el.isActive === true)


                  stakingData.sort((a, b) => b.startTime - a.startTime)

                  let refNumber = await _this.getReferrals(userAddress, "V1");
                  refNumber += await _this.getReferrals(userAddress, "V2");
                  _this.context.$store.commit('setReferralsNumber', refNumber)
                  _this.context.$store.commit('setStakesInfo', stakingData)
                  console.log(stakingData);
                  console.log('active stakes', activeStakes);


            setTimeout(tick, period);
            } catch (ex) {
            console.log(ex);
            setTimeout(tick, 300);
            }
        }, 300);
        

    }

    // async getFarmingTokens() {
    //     let stakeTokensAddresses = Config.stakeTokens;
    //     let farmTokensAddresses = []

    //     // for (let i = 0; i < stakeTokensAddresses.length; i++){
    //     //     let address = await this.farm.getPoolTokens(stakeTokensAddresses[i].address);
    //     //     farmTokensAddresses.push(address)
    //     // }

    //     let allTokens = (stakeTokensAddresses.map((token, index) => {
    //         let farmTokensData = farmTokensAddresses[index].map(el => {
    //             let farmTokenData = Config.stakeTokens.find(farmToken =>  farmToken.address === el).farmTokens;
    //             return farmTokenData
    //             })

    //         return {
    //             tokenName: token.name,
    //             tag: token.tag,
    //             tokenAddress: token.address,
    //             tokenDecimals: token.decimal,
    //             userMinStake: token.userMinStake,
    //             userMaxStake: token.userMaxStake,
    //             totalMaxStake: token.totalMaxStake,
    //             // lockableDays: token.lockableDays,
    //             // optionableStatus: token.optionableStatus,
    //             farmTokensData: farmTokensData,
    //         }

    //     }))

    //     return allTokens
    // }

    async calcRewardForStakeTokens(stakingData, totalStakes, contractVersion) {
        const poolStartTime = Config.CONTRACT_VERSION_CONFIG[contractVersion].POOL_START_TIME
                
        let poolEndTime = poolStartTime + (86400 * Config.CONTRACT_VERSION_CONFIG[contractVersion].STAKE_DAYS) //seconds in 24 hours * 90 days
        let currentTime = Math.floor(new Date().getTime() / 1000);

        for (let i=0; i<stakingData.length; i++) {
            if(stakingData[i].isActive === true){
              let tokenAddress = stakingData[i].tokenAddress;
              let stakeAmount = stakingData[i].stakeAmount;
              let stakeDate = stakingData[i].startTime;
              let totalStakesForStakeToken = totalStakes[tokenAddress.toLowerCase()]


              let farmTokensReward = [];
              let farmTokemsPossibleRewards = [];
              for(let j = 0; j< stakingData[i].farmTokensList.length; j++){
                const interval = stakingData[i].farmTokensList[j].interval * 86400;
                if(poolEndTime > currentTime){
                    poolEndTime = currentTime
                }
                
                let possibleRewardEarned;
                const daysPassedBeforeStake = Math.floor((stakeDate - Config.CONTRACT_VERSION_CONFIG[contractVersion].POOL_START_TIME) / 3600 / 24)

                const userHourlyReard = (Number(stakeAmount) * stakingData[i].farmTokensList[j].hourlyReward) / (totalStakesForStakeToken)

                const possibleReward =
                    Number(userHourlyReard * 24) *
                    ((Config.CONTRACT_VERSION_CONFIG[contractVersion].STAKE_DAYS - daysPassedBeforeStake) - stakingData[i].farmTokensList[j].interval);

                const fivePercFee = possibleReward * 5 / 100
                
                if(stakingData[i].referrer != "0x0000000000000000000000000000000000000000"){
                    possibleReward *= 0.95;
                }else {
                    possibleReward *= 0.9;
                }

            
               if((interval === 86.4 || interval === 86400) && currentTime >= (stakeDate + 3600)){ //rewards are accesseble after 1hour
                    const rewardForOneDay = (Number(stakeAmount) * stakingData[i].farmTokensList[j].hourlyReward) / (totalStakesForStakeToken)


                    possibleRewardEarned  = Math.floor((poolEndTime - stakeDate) / 3600) * rewardForOneDay;
                    
                    let refReward = possibleRewardEarned * 5 / 100;

                    if(stakingData[i].referrer !== "0x0000000000000000000000000000000000000000") {
                        possibleRewardEarned *= 0.95;
                    }else {
                        possibleRewardEarned *= 0.9;
                    }
               }else if (interval > 86400 && currentTime >= (stakeDate + interval + 3600)){
                    const rewardForOneDay = (Number(stakeAmount) * stakingData[i].farmTokensList[j].hourlyReward) / (totalStakesForStakeToken)

                    let noOfDaysInHours = Math.floor((poolEndTime - stakeDate) / 3600);
                    possibleRewardEarned  = Math.floor(noOfDaysInHours - ((interval - 86400) / 3600)) * rewardForOneDay;

                    let refReward = possibleRewardEarned * 5 / 100;

                    if(stakingData[i].referrer !== "0x0000000000000000000000000000000000000000") {
                        possibleRewardEarned -= refReward;
                    }else {
                        possibleRewardEarned -= (refReward*2);
                    }
               }else {
                    possibleRewardEarned = 0
               }
               

              farmTokensReward.push(possibleRewardEarned);
              farmTokemsPossibleRewards.push(possibleReward)


              }
              
              stakingData[i]['farmTokensReward'] =  farmTokensReward 
              stakingData[i]['farmTokensPossibleReward'] = farmTokemsPossibleRewards
              stakingData[i]['maxTokensStake']= totalStakesForStakeToken
            }
            continue
          }

          return stakingData
    }

    async getStakingDetails(userAddress, contractVersion) {
        if(!this[contractVersion]) {
            await this.init()
        }

        
        let resultRaw = await this[contractVersion].viewStakingDetails(userAddress);
        let result = []


    
        function getCol(array, col){
            var column = [];
            for(var i=0; i<array.length; i++){
               column.push(array[i][col]);
            }
            return column; 
         }
             
        for(let i = 0; i < resultRaw[0].length; i++){
            
            let column = getCol(resultRaw, i)
            let decimals = Config.stakeTokens.find(el => el.address.toLocaleLowerCase() === column[1].toLocaleLowerCase()).decimals

            let stakeObj = {
                referrer: column[0],
                tokenAddress: column[1],
                isActive: column[2],
                stakeId: Number.parseInt(column[3]),
                stakeAmount: Number(column[4]) / 10 ** decimals,
                startTime: Number.parseInt(column[5]),
            }
            result.push(stakeObj);
        }
        result.sort((a, b) => b.startTime - a.startTime)

        return result;
        
    }

    async getUserTotalStaking(userAddress, tokenAddress, contractVersion) {
        let resultRaw = await this[contractVersion].userTotalStaking(userAddress, tokenAddress);

    }

    async stake(tokenAddress, amount, decimals, ref="", contractVersion) {
        if(!ref) {
            ref = this.defaultReferrer;
        }

        amount =  ethers.utils.parseUnits(`${amount}`, decimals)

        let rawTransactions = await this[contractVersion].stake(ref, tokenAddress, amount, {gasPrice: ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei")})



        return rawTransactions;
    }

    async unstake(userAddress, stakeId, contractVersion){
        let rawTransactions = await this[contractVersion].unstake(userAddress, stakeId, {gasPrice: ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei")})

        return rawTransactions;
    }

    async approve(tokenContract, amount, decimals, contractVersion){

        amount = ethers.utils.parseUnits(`${amount}`, decimals)

        const rawTransaction = await tokenContract.approve(this[contractVersion].address, amount)
        return rawTransaction;
    }

    async totalTokensCount(contractVersion){
        const result = await this[contractVersion].totalTokensCount();
        return result;
    }

    async getPoolStartTime(contractVersion) {
        const resultRaw = await this[contractVersion].poolStartTime();
        return Number.parseInt(resultRaw._hex)
    }

    async getTotalStaking(poolAddress, decimals) {
        let V1TotalStake;
        let V2TotalStake;

        if(poolAddress.toLowerCase() === "0x510b29a93ebf098f3fc24a16541aaa0114d07056" || poolAddress.toLowerCase() === "0x9e0eaf240eebed129136f4f733480feabbca136b" || poolAddress.toLowerCase() === "0xf74ee1e10e097dc326a2ad004f9cc95cb71088d3") {
            const pool1ResultRaw = await this.V1.maxTotalStaked(poolAddress);
            V1TotalStake = Number(ethers.utils.formatUnits(pool1ResultRaw._hex, decimals));
            V2TotalStake = 0
        }else if (poolAddress === "0xe412f518a6a39351c965e201a329ec83047feb4a") {
            const pool1ResultRaw = await this.V1.maxTotalStaked(poolAddress);
            const pool2ResultRaw = await this.V2.maxTotalStaked(poolAddress);
            V1TotalStake = Number(ethers.utils.formatUnits(pool1ResultRaw._hex, decimals));
            V2TotalStake = Number(ethers.utils.formatUnits(pool2ResultRaw._hex, decimals));
            

        }else {
            const pool2ResultRaw = await this.V2.maxTotalStaked(poolAddress);
            V1TotalStake = 0
            V2TotalStake = Number(ethers.utils.formatUnits(pool2ResultRaw._hex, decimals));
        }

       

        return {
            V1TotalStake,
            V2TotalStake
        };
    }
    async getTotalStakingOnSpecificContract(poolAddress, tokenContract) {
        const poolResultRaw = await this[tokenContract].maxTotalStaked(poolAddress);
        const totalStake = Number(ethers.utils.formatUnits(poolResultRaw._hex));

        return totalStake
    }

    async checkReferrer(refAddress, contractVersion) {
        const result = await this[contractVersion].isActiveUser(refAddress);

        return result;
    }

    async getReferrals(userAddress, contractVersion) {
        if(!this[contractVersion]){
            await this.init()
        }

        const result = await this[contractVersion].users(userAddress);
        return Number.parseInt(result._hex);
    }

    async getUserReward(userAddress, contractVersion, period=60000) { /* miliseconds */
            let _this = this;

            setTimeout(async function tick() {
            try {
                
                let blockchainLastBlock = await _this.providerAddress.getBlockNumber()
                _this.context.$store.commit("setLastBlock", blockchainLastBlock)

                let startBlock = Config.CONTRACT_VERSION_CONFIG[contractVersion].START_BLOCK;

                let lastBlock
                //берем ивенты
                let events = JSON.parse(localStorage.getItem(`${userAddress}`));
                //если пусто
                if(!events) {
                    lastBlock = Number(startBlock);// not used 

                }else if (contractVersion === "V1" && events && events.from){
                    //если есть ивенты, сканируем от последнего записанного блока по +=5000
                    startBlock = Number(events.from) + 1

                }else if (contractVersion === "V2" && events && events.V2from){
                    startBlock = Number(events.V2from) + 1
                }else if (contractVersion === "V2" && events && !events.V2from){
                    lastBlock = Number(startBlock);// not used 
                }

              //устанавливаем по какой блок сканировать ивенты
                for (let from = startBlock; from <= blockchainLastBlock;) {

                if(from > blockchainLastBlock){
                    from = blockchainLastBlock;
                }
                let to = from + 5000
                // let filterClaim = _this[contractVersion].filters.Claim(userAddress);
                // filterClaim.fromBlock = from;
                // filterClaim.toBlock = to;
                // let eventsRegister = await _this.providerAddress.getLogs(filterClaim)

                let resultClaimRaw = await _this[contractVersion].queryFilter("Claim", from, to)

                resultClaimRaw.forEach(el => {
                    return el.poolVersion = contractVersion
                })
                
                let resultRefRaw = await _this[contractVersion].queryFilter('ReferralEarn', from, to)
                resultRefRaw.forEach(el => {
                    return el.poolVersion = contractVersion
                })
                

                let claimEvents = resultClaimRaw.map(el => {
                    let decimals = 18
                    if(el.args[2].toLowerCase() === "0xc7d8d35eba58a0935ff2d5a33df105dd9f071731".toLowerCase()){
                        decimals = 6
                    }
                    if(el.args[2].toLowerCase() === "0x6bfd576220e8444ca4cc5f89efbd7f02a4c94c16".toLowerCase()){
                        decimals = 8
                    }
                    return {
                        eventName: el.event,
                        userAddress: el.args[0],
                        stakedTokenAddress: el.args[1],
                        tokenAddress: el.args[2],
                        amount: Number(ethers.utils.formatUnits(el.args[3], decimals)),
                        stakeId: Number.parseInt(el.args[4]),
                        endTime: Number.parseInt(el.args[5]),
                        transactionHash: el.transactionHash,
                        poolVersion: el.poolVersion
                    }
                })
 
                let refRewardsEvents = resultRefRaw.map(el => {
                    let decimals = 18
                    if(el.args[2].toLowerCase() === "0xc7d8d35eba58a0935ff2d5a33df105dd9f071731".toLowerCase()){
                        decimals = 6
                    }
                    if(el.args[2].toLowerCase() === "0x6bfd576220e8444ca4cc5f89efbd7f02a4c94c16".toLowerCase()){
                        decimals = 8
                    }

                    
                    return {
                        eventName: el.event,
                        userAddress: el.args[0],
                        callerAddress: el.args[1],
                        rewardTokenAddress: el.args[2],
                        rewardAmount: Number(ethers.utils.formatUnits(el.args[3], decimals)),
                        endTime: Number.parseInt(el.args[4]),
                        transactionHash: el.transactionHash,
                        poolVersion: el.poolVersion
            
                    }
                })

                let userClaimEvents = claimEvents.filter(event => event.userAddress.toLowerCase() === userAddress.toLowerCase());
                let userRefRewardsEvents = refRewardsEvents.filter(event => event.userAddress.toLowerCase() === userAddress.toLowerCase());

                let userEvents = JSON.parse(localStorage.getItem(`${userAddress}`)) || [];

                if (userEvents.length === 0){

                    userEvents = {
                        from,
                        claimEvents: [...userClaimEvents],
                        refReward: [...userRefRewardsEvents]
                    };
                    localStorage.setItem(`${userAddress}`, JSON.stringify(userEvents))
                }else{

                    let previousClaimEvents = userEvents.claimEvents || [];
                    let previousLastBlockV1 = userEvents && userEvents.from || Config.CONTRACT_VERSION_CONFIG["V1"].START_BLOCK;
                    let previousLastBlockV2 = userEvents && userEvents.V2from || Config.CONTRACT_VERSION_CONFIG["V2"].START_BLOCK;
                    let previousRefReward = userEvents.refReward || [];

                    let claimEvents = [...previousClaimEvents, ...userClaimEvents]
                    claimEvents = claimEvents.reduce(
                        (x, y) => x.findIndex(e => (e.amount === y.amount && e.tokenAddress === y.tokenAddress && e.stakedTokenAddress === y.stakedTokenAddress && e.poolVersion === y.poolVersion)) < 0 ? [...x, y]: x, [])

                    let refReward = [...previousRefReward, ...userRefRewardsEvents];
                    refReward = refReward.reduce(
                      (x, y) => x.findIndex(e => (e.transactionHash === y.transactionHash && e.rewardTokenAddress === y.rewardTokenAddress && e.callerAddress === y.callerAddress && x.rewardTokenAmount && y.rewardTokenAmount)) < 0 ? [...x, y]: x, []
                    )
                    
                    

                    if(contractVersion === "V2") {

                        userEvents = {
                            V2from: from,
                            claimEvents,
                            refReward
                        }
                        userEvents.from = previousLastBlockV1


                    }else if(contractVersion === "V1"){
                        userEvents = {
                            from,
                            claimEvents,
                            refReward
                        }
                        userEvents.V2from = previousLastBlockV2
                    }
                    localStorage.setItem(`${userAddress}`, JSON.stringify(userEvents))
                }
                if(from === blockchainLastBlock) {

                    setTimeout(tick, period)
                    return
                }else if (from + 5000 > blockchainLastBlock) {

                    
                    from = blockchainLastBlock
                    

                }else {

                    from+= 5000

                }
            

                }
                setTimeout(tick,period)
            } catch (error) {
              console.log(error);
              setTimeout(tick, 300)
            }
        }, 300)
        
    }

    async getStakeAmount(userAddress, stakeId, contractVersion, decimals) {
        
        let resultRaw = await this[contractVersion].getStakeAmount(userAddress, stakeId);
        
        let result = Number(ethers.utils.formatUnits(resultRaw, decimals));
        return result;
    }

    async getRewardForPeriod(stakedAmount, stakedToken, rewardToken, contractVersion){

        
        try {
            let decimals = Config.tokens.find(el = el.address.toLowerCase() === stakedToken.toLowerCase()).decimals
            const farmTokenDecimals = Config.tokens.find(el => el.address.toLowerCase() === stakedToken.toLowerCase()).farmTokensList.find(farmEl => farmEl.address.toLowerCase() === rewardToken.toLowerCase()).decimals
            let {V1TotalStake, V2TotalStake} = await this.getTotalStaking(stakedToken, decimals);

                V1TotalStake = V1TotalStake === 0 ? stakedAmount : V1TotalStake 
                V2TotalStake = V2TotalStake === 0 ? stakedAmount : V2TotalStake

                if(contractVersion === "V1"){
                    const totalStakingBig = ethers.utils.parseUnits(`${V1TotalStake}`, decimals)
                    const stakedAmountBig = ethers.utils.parseUnits(`${stakedAmount}`, decimals)
                    
                    const rewardRaw = await this[contractVersion].getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, totalStakingBig);
                    const reward = (ethers.utils.formatUnits(rewardRaw, farmTokenDecimals));

                    return reward
                }else if (contractVersion === "V2"){
                    const totalStakingBig = ethers.utils.parseUnits(`${V2TotalStake}`, decimals)
                    const stakedAmountBig = ethers.utils.parseUnits(`${stakedAmount}`, decimals)

                    const rewardRaw = await this[contractVersion].getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, totalStakingBig);
                    const reward = (ethers.utils.formatUnits(rewardRaw, farmTokenDecimals));

                    return reward;
                }

        } catch (error) {
            console.log(error);
        }
        
    }


    async getPossibleDailyReward(stakedAmount, stakedToken, rewardToken) {
        try {
            let decimals = Config.tokens.find(el = el.address.toLowerCase() === stakedToken.toLowerCase()).decimals
            const farmTokenDecimals = Config.tokens.find(el => el.address.toLowerCase() === stakedToken.toLowerCase()).farmTokensList.find(farmEl => farmEl.address.toLowerCase() === rewardToken.toLowerCase()).decimals
            // let {V1TotalStake, V2TotalStake} = await this.getTotalStaking(stakedToken)
            let {V1TotalStake} = await this.getTotalStaking(stakedToken, decimals)
            V1TotalStake = V1TotalStake === 0 ? stakedAmount : V1TotalStake 
            V2TotalStake = V2TotalStake === 0 ? stakedAmount : V2TotalStake

            const stakedAmountBig = ethers.utils.parseUnits(`${stakedAmount}`, decimals)

            const V1newTotalStaking = Number(stakedAmount) + V1TotalStake;
            const V1newTotalStakingBig = ethers.utils.parseUnits(`${V1newTotalStaking}`, decimals);

            const V2newTotalStaking = Number(stakedAmount) + V2TotalStake;
            const V2newTotalStakingBig = ethers.utils.parseUnits(`${V2newTotalStaking}`, decimals);

            const V1RewardRaw = await this.V1.getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, V1newTotalStakingBig);
            const V1Reward = (ethers.utils.formatUnits(V1RewardRaw, farmTokenDecimals));

            const V2RewardRaw = await this.V2.getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, V2newTotalStakingBig);
            const V2Reward = (ethers.utils.formatUnits(V2RewardRaw));

            return {
                V1Reward,
                V2Reward
            }

        } catch (error) {
            console.log(error);
        }
    }

    withoutRound(number, roundTo=2){

        if(!number || !isFinite(number)){
            return roundTo  ===  2 ? "0.00" : roundTo  ===  4 ? "0.0000" : "0.00000000"  
        }

        if(roundTo === 2){
            if(number.toString().indexOf(".") !== -1){

                 const splittedNumber = number.toString().split(".")
                 splittedNumber[1]+="00";
                 number = splittedNumber.join(".");

             return (number.toString()).match(/^-?\d+(?:\.\d{0,2})?/)[0]
             }else {
                 number = number.toString()+".00";
                 return (number.toString()).match(/^-?\d+(?:\.\d{0,2})?/)[0]
             }
        }else if(roundTo === 4){
             if(number.toString().indexOf(".") !== -1){

                 const splittedNumber = number.toString().split(".")
                 splittedNumber[1]+="00";
                 number = splittedNumber.join(".");

             return (number.toString()).match(/^-?\d+(?:\.\d{0,4})?/)[0]
             }else {
                 number = number.toString()+".00";
                 return (number.toString()).match(/^-?\d+(?:\.\d{0,4})?/)[0]
             }
        }else if(roundTo === 8){
                if(number.toString().indexOf(".") !== -1){

                    const splittedNumber = number.toString().split(".")
                    splittedNumber[1]+="00";
                    number = splittedNumber.join(".");

                return (number.toString()).match(/^-?\d+(?:\.\d{0,8})?/)[0]
                }else {
                    number = number.toString()+".00";
                    return (number.toString()).match(/^-?\d+(?:\.\d{0,8})?/)[0]
                }
        }
    }

    
}