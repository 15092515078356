<template>
    <div class="modal">
        <div class="modal-wrapper">
          <div class="modal-header">
              <div class="title">bRing BSC Pools</div>
              <button @click="$emit('close')" data-cv="btn-close-switch-wallet"> <i class="i-close"></i></button>
          </div>
          <div class="modal-content">
              <ul class="modal-list_pools"  data-cy="list-select-wallet">
                <li v-for="pool in pools" :key="pool.id" class="wrapper_pool">
                     <div class="list_pools-item card_pool">
                        <div>
                        <div class="h2">RING#{{Number(pool.poolVersion.split('')[1])+1}} | {{tokenTicker(pool.name)}}
                        </div>
                        <div class="token">
                          <div class="token_img"><div :class="pool.name"></div></div>
                          <div class="token_title">{{tokenTicker(pool.name)}}</div>
                        </div>
                        <div class="pool-block">
                  <div class="description">
                    <div class="title-description">{{lang.get("POOL_STRENGTH")}}</div>
                    <div class="value-description">
                        {{getPoolStrengthNoWallet(pool) ? getPoolStrengthNoWallet(pool) : 0 }} %
                    </div>
                  </div>
                  <div class="progress">
                    <div :style="{ width: getPoolStrengthNoWallet(pool) + '%' }"></div>
                  </div>
                        </div>
                        </div>
                        <div>
                            <div class="h2">APY</div>
                            <div class="h1 h1-pool_apy h1-green">{{poolAPYPercentageNoWallet(pool)}}%</div>
                            <div class="addinfo addinfo-end_date">
                              <i class="i-calendar"></i>
                              <span>Ends in:</span>
                              <span v-if="poolEndsIn(pool) > 0">{{poolEndsIn(pool)}} days</span>
                              <span v-else >Pool is closed </span>
                            </div>
                            <div  class="pool-reward">
                              <div v-for="farmToken in getFarmTokensForPoolNoWallet(pool)" :key="farmToken.address" class="token_img"><div :class="farmToken.name"></div></div>
                            </div>  
                        </div>
                     </div>
                </li>  
                
              </ul>
          </div>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Config from '../../../Config.json'
import MultiLang from '@/core/multilang'; 


export default {
    name: 'PoolStatsModal',
    data(){
        return{
            lang: new MultiLang(this)
        }
    },
    computed: {
        ...mapState(['tokensTotalStake']),
        pools() {
            const reversedArray = [...Config.stakeTokens]
            return reversedArray.sort((a,b) => b.id - a.id)
        },
        

    },
    methods: {
        poolEndsIn(pool){
            const currentDate = Math.floor(new Date().getTime() / 1000)
            const daysPassed = Math.floor((currentDate - Config.CONTRACT_VERSION_CONFIG[pool.poolVersion].POOL_START_TIME) / 3600 / 24)
            return Config.CONTRACT_VERSION_CONFIG[pool.poolVersion].STAKE_DAYS - daysPassed > 0 ? Config.CONTRACT_VERSION_CONFIG[pool.poolVersion].STAKE_DAYS - daysPassed : 0
        },
        getPoolStrengthNoWallet(pool){
            const poolAddress = pool.address.toLowerCase()
            const totalMaxStake = Config.stakeTokens.find(token => token.address.toLowerCase() === poolAddress &&  pool.poolVersion === token.poolVersion).totalMaxStake;

            const res =  this.tokensTotalStake[pool.poolVersion][poolAddress] / totalMaxStake * 100  > 100
            ? 100
            : (this.tokensTotalStake[pool.poolVersion][poolAddress] / totalMaxStake) * 100;
            return this.$root.core.withoutRound(res)
        },
        poolAPYPercentageNoWallet(pool){
                const poolPercent = pool.poolVersion === "V1" ? 150 : 60
                const poolAddress = pool.address.toLowerCase()
                const coefTokens = Config.stakeTokens.find(token => token.address.toLowerCase() === poolAddress && token.poolVersion === pool.poolVersion).coefTokens
                const poolTotalStake = this.tokensTotalStake[pool.poolVersion][poolAddress]

                const resCoef = (poolPercent  * coefTokens / poolTotalStake);

                return this.$root.core.withoutRound(resCoef) === "0.00" ? "N/A" : this.$root.core.withoutRound(resCoef);

      
        },

        getFarmTokensForPoolNoWallet(pool){
            return Config.stakeTokens.find(el => pool.address.toLowerCase() === el.address.toLowerCase() && pool.poolVersion === el.poolVersion).farmTokensList
        },

        tokenTicker(tokenName) {
        return Config.tokenAliases[tokenName];
        }
    },
    mounted() {
        this.lang.init()
    }
}
</script>