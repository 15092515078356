import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue"
import NotFound from "../components/NotFound.vue"


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/cabinet/pool",
    name: "Home",
    component: Home,
  },
  {
    path: '/cabinet',
    component: () => import('@/views/Cabinet.vue'),
    children: [
        {
            path: '/',
            name: 'cabinet',
            component: () => import('@/views/cabinet/Index.vue'),
            children: [
                {
                    path: 'pool',
                    name: 'pool',
                    component: () => import('@/views/cabinet/tabs/Pool'),
                },
                {
                    path: 'stake',
                    name: 'stake',
                    component: () => import('@/views/cabinet/tabs/Stake'),
                },   
                {
                    path: 'reward',
                    name: 'reward',
                    component: () => import('@/views/cabinet/tabs/Reward')
                },
            ],
        }, 
    ]
  },
  {
    path: "/:notFound(.*)",
    component: NotFound,
    name: "NotFound"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
