<template>
  <div>
     <main class="cabinet not_found">
      <div class="container">
        <div class="not_found-container">
          <div class="not_found-title">404</div>
          <div class="not_found-description">page not found</div>
          <h3>Redirecting you to stake page in {{secondsLeft}}...</h3>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      secondsLeft: 3
    }
  },
  created(){
    setTimeout(() => {
      this.$router.push('/cabinet/pool')
    }, 3000)
    const interval = setInterval(() => {
      this.secondsLeft -= 1;
      if(this.secondsLeft == 0){
        clearInterval(interval)
      }
  }, 1100);
  }
}
</script>